<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardText>
            <VForm>
              <VTextField
                v-model.trim="id"
                label="ID штатной должности"
              />

              <VTextField
                v-model.trim="staffPosition.personId"
                label="UUID пользователя назначенного на штатную должность"
              />

              <VTextField
                v-model.trim="staffPosition.teamId"
                label="UUID команды из оргстурктуры"
              />

              <VTextField
                v-model.trim="staffPosition.name"
                label="название штатной должности"
              />

              <VCheckbox
                v-if="isShowMainCheckbox"
                v-model="staffPosition.main"
                label="главная ли это Штатная Должность у пользователя"
              />
            </VForm>
          </VCardText>

          <VCardActions class="px-4">
            <VBtn
              class="mr-3"
              depressed
              color="primary"
              @click="updatePosition"
            >
              Обновить
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'OrgPositionEdit',

  data() {
    return {
      staffPosition: {
        personId: '',
        teamId: '',
        role: '',
        name: '',
        main: false,
        head: false,
      },
    };
  },

  computed: {
    id() {
      return this.$route.params.positionId;
    },
    isShowMainCheckbox() {
      return !!this.staffPosition.personId;
    },
  },

  watch: {
    isShowMainCheckbox(value) {
      if (!value) {
        // если чекбокс скрывается, то устанавливаем ему значение false
        this.staffPosition.main = false;
      }
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        const data = { id: this.id };

        const { staffPosition } = await this.$di.api.Orgstructure.positionShow(data);

        this.staffPosition = staffPosition;
        delete this.staffPosition.id;
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      }
    },
    updatePosition() {
      const { id, staffPosition } = this;
      this.$di.api.Orgstructure
        .positionUpdate({ id, staffPosition })
        .then(() => {
          this.$di.notify.snackSuccess('Позиция обновлена');
        })
        .catch(this.$di.notify.errorHandler);
    },
  },
};
</script>
